import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BizPitchLogo = () => (
  <StaticImage
    src="../../images/bizpitch_logo.png"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    style={{ maxWidth: "175px" }}
    alt="BizPitch logo. Reads: 2020 bizpitch finalist"
  />
)

export default BizPitchLogo
