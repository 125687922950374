import React from "react"
import styled from "styled-components"

import Section from "./section"
import BizpitchLogo from "./images/bizpitchLogo"
import MaxWidthWrapper from "./maxWidthWrapper"
import Heading from "./typography/heading"

const Bizpitch = () => (
  <Section>
    <Wrapper>
      <MaxWidthWrapper width={700}>
        <Heading tag="h2">
          Dr. Caudill was a 2020 BizPitch Savannah finalist!
        </Heading>
      </MaxWidthWrapper>
      <BizpitchLogo />
    </Wrapper>
  </Section>
)

const Wrapper = styled.div`
  border-top: 3px solid var(--carrot);
  border-bottom: 3px solid var(--carrot);
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
`

export default Bizpitch
