import React from "react"

import Section from "./section"
import Calendly from "./calendly"

const Join = () => (
  <Section background="chia" color="sec-dark">
    <Calendly first />
  </Section>
)

export default Join
