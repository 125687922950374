import React from "react"
import styled from "styled-components"

import Section from "./section"
import Heading from "./typography/heading"
import Dual from "./dual"
import MomImage from "./images/momImage"

const Mom = () => (
  <Section color="sec-light">
    <Title tag="h3" as="p" role="doc-subtitle">
      A LETTER FROM THE DOC
    </Title>
    <StyledDual>
      <CopyWrapper>
        <Heading tag="h2">Hi, I'm Dr. Yolanda Rivera-Caudill.</Heading>
        <p>
          Also known as Dr. Caudill, or most recently, the Dr. Beet. After 30
          years as a Pediatrician, I’m excited to announce the founding of my
          practice. The Tiny Beet is a general pediatric direct primary care
          (DPC) practice specializing in Lifestyle Medicine in order to focus on
          the root cause of my patients’ health.
        </p>
        <p>
          I invite you and your family to join me in improving the health in our
          Savannah community, a tiny step at a time.
        </p>
        <p>
          Dr. Yolanda Rivera-Caudill, MD, FAAP, DipABLM <br />
          “The Dr. Beet”
        </p>
      </CopyWrapper>
      <ImageWrapper>
        <MomImage />
      </ImageWrapper>
    </StyledDual>
  </Section>
)

const Title = styled(Heading)`
  max-width: 900px;
  margin: 0 auto 2rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
  font-size: ${25 / 16}rem;
  font-weight: 400;

  border-bottom: 1px solid white;
  border-top: 1px solid white;
`

const StyledDual = styled(Dual)`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`
const ImageWrapper = styled.div`
  max-width: 423px;
  @media (min-width: 1000px) {
    flex: 1 1 423;
  }
`

const CopyWrapper = styled.div`
  max-width: 423px;
  order: 1;
  @media (min-width: 1000px) {
    flex: 1 1 423;
    order: 0;
  }
`
export default Mom
