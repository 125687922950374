import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const MotherDaughter = () => (
  <StaticImage
    src="../../images/mother-daughter.jpg"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    alt="A mother standing behind her daughter and helping her prepare dinner."
    style={{ borderRadius: "50%" }}
  />
)

export default MotherDaughter
