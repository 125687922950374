import React from "react"
import styled from 'styled-components';
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Section from "./section"
import Heading from "./typography/heading"
import Body from "./typography/bodyCopy"
import CalendlyButton from "./calendlyButton"

const ComingSoon = () => {
  const breakpoints = useBreakpoint()

  return (
    <Section
      color="pri-light"
      background={breakpoints.l ? "cottage" : "fruits"}
    >
      <RootWrapper>
        <CardWrapper>
          <Heading tag="h1">
            Savannah’s 1st Pediatric Lifestyle Medicine DPC.
          </Heading>
          <StyledBody>
            Dr. Caudill is pleased to announce her pediatric practice, The Tiny
            Beet. Secure your spot by scheduling a Meet &amp; Greet with Doctor
            Beet! Scroll on to learn more!
          </StyledBody>
          <ButtonWrapper>
            <CalendlyButton
              url="https://calendly.com/thetinybeet/meet-greet"
              pageSettings={{
                hideLandingPageDetails: true,
                hideEventTypeDetails: true,
              }}
            />
          </ButtonWrapper>
        </CardWrapper>
      </RootWrapper>
    </Section>
  )
}

const RootWrapper = styled.div`
  /* margin-top: 90px; */
  height: 80vh;
  max-width: 270px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 300px) {
    height: 100vh;
    padding-top: 3rem;
  }
  @media (min-width: 700px) {
    max-width: 484px;
  }

  @media (min-width: 1100px) {
    max-width: unset;
  }
`

const CardWrapper = styled.div`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 700px;

  @media (min-width: 1233px) {
    top: 215px;
    left: -305px;
    background: var(--sec-light);
    height: fit-content;
    width: 520px;
    padding: 24px 32px;
  }
`

const StyledBody = styled(Body)`
  margin: 0 1.4rem 1rem;
  font-size: 1rem;
  text-align: left;
  max-width: 700px;
`

const ButtonWrapper = styled.div`
  @media (min-width: 1233px) {
    position: absolute;
    bottom: 0;
    transform: translateY(51%);
  }
`

export default ComingSoon
