import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HouseIcon = props => (
  <StaticImage
    src="../../images/dpcHouse.png"
    placeholder="blurred"
    loading="lazy"
    layout="fixed"
    height={80}
    alt="minimalist outline of the front of a house"
  />
)

export default HouseIcon
