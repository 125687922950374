import React from "react"
import styled from "styled-components"

import Section from "./section"
import Heading from "./typography/heading"
import Body from "./typography/bodyCopy"
import MainLogo from "./images/mainLogo"
import DualSection from "./dual"

const Intro = () => (
  <RootWrapper>
    <DualSection>
      <LogoWrapper>
        <MainLogo />
      </LogoWrapper>
      <CopyWrapper>
        <StyledHeading tag="h2">
          Welcome to The Tiny Beet, we are here to inspire families of Savannah
          to live healthier lifestyles.
        </StyledHeading>
        <Body>
          Dr. Caudill is on a mission to help her patients develop and maintain
          healthier lifestyles through general pediatrics and lifestyle
          medicine. The Tiny Beet is a direct patient care practice.
        </Body>
      </CopyWrapper>
    </DualSection>
  </RootWrapper>
)

const RootWrapper = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`

const CopyWrapper = styled.div`
  max-width: 700px;
  @media (min-width: 1000px) {
    flex: 1 1 500px;
  }
`

const StyledHeading = styled(Heading)`
  color: var(--carrot);
`

const LogoWrapper = styled.div`
  flex: 1 1 400px; 
  max-width: 400px;
  min-width: 321px;

  @media (min-width: 1000px) {
  }
`

export default Intro
