import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import styled from "styled-components"

import Section from "./section"
import Dual from "./dual"
import Grid from "./dpcGrid"
import Heading from "./typography/heading"
import Button from "./button"

const DPC = () => {
  const breakpoints = useBreakpoint()

  return (
    <Section color="pri-med">
      <StyledDual>
        <div>
          <Heading tag="h2">The benefits of a DPC practice.</Heading>
          <p>
            A DPC model, similar to concierge medicine, allows for a tailored
            experience with each individual patient and works as a monthly
            membership. Dr. Caudill is looking forward to spending more time
            with her patients at The Tiny Beet.
          </p>
          {!breakpoints.sm && (
            <Button to="/learn-more#pricing" title="DPC pricing">
              Learn more about DPC
            </Button>
          )}
        </div>
        <GridWrapper>
          <Grid />
          {breakpoints.sm && (
            <Button to="/learn-more#pricing" title="DPC pricing">
              Learn more about DPC
            </Button>
          )}
        </GridWrapper>
      </StyledDual>
    </Section>
  )
}

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledDual = styled(Dual)`
  @media (min-width: 700px) {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export default DPC
