import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const VeggiesIcon = props => (
  <StaticImage
    src="../../images/dpcVeggies.png"
    placeholder="blurred"
    loading="lazy"
    layout="fixed"
    height={80}
    alt="minimalist outline of vegetables"
  />
)

export default VeggiesIcon
