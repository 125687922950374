import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Section from "./section"
import MaxWidthWrapper from "./maxWidthWrapper"

function Quote({ quote, by, color }) {
  return (
    <StyledSection background="chia" color={color}>
      <MaxWidthWrapper width={650}>
        <QuoteBlock>{quote}</QuoteBlock>
        <Byline> &mdash;{by}</Byline>
      </MaxWidthWrapper>
    </StyledSection>
  )
}

const StyledSection = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

const QuoteBlock = styled.blockquote`
  font-family: var(--font-family-primary);
  --type-fontsize-min: 2.5rem;
  --type-fontsize-max: 3.2rem;
  --type-fontsize-value: 0.7vw + 2.25rem;
  font-size: clamp(
    var(--type-fontsize-min),
    var(--type-fontsize-value),
    var(--type-fontsize-max)
  );
  text-align: center;
  font-weight: 400;
  display: inline-block;
  width: fit-content;
  color: white;
  line-height: 1.25;
`

const Byline = styled.cite`
  display: block;
  text-align: right;
  margin-right: 2rem;
  color: white;
  font-size: ${18 / 16}rem;
`

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  by: PropTypes.string.isRequired,
  color: PropTypes.string,
}

Quote.defaultProps = {
  color: "sec-dark",
}

export default Quote
