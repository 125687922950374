import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HoopIcon = props => (
  <StaticImage
    src="../../images/dpcHoop.png"
    placeholder="blurred"
    loading="lazy"
    layout="fixed"
    height={80}
    alt="minimalist outline of basketball hoop"
  />
)

export default HoopIcon
