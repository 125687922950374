import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const MotherDaughter = () => (
  <StaticImage
    src="../../images/dr-rivera.png"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    alt="A picture of Dr. Caudill smiling, wearing her physician's coat, and red glasses"
  />
)

export default MotherDaughter
