import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const TimerIcon = props => (
  <StaticImage
    src="../../images/dpcTimer.png"
    placeholder="blurred"
    loading="lazy"
    layout="fixed"
    height={80}
    alt="minimalist outline of an hourglass"
  />
)

export default TimerIcon
