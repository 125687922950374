import React from "react"
import styled from "styled-components"

import BoardIcon from "./images/iconBoard"
import ClockIcon from "./images/iconClock"
import DoctorIcon from "./images/iconDoctor"
import HoopIcon from "./images/iconHoop"
import HouseIcon from "./images/iconHouse"
import PhoneIcon from "./images/iconPhone"
// import SyringeIcon from './images/iconSyringe'
import TimerIcon from "./images/iconTimer"
import VeggiesIcon from "./images/iconVeggies"
import GridItem from "./dpcGridItem"

const Grid = () => (
  <GridWrapper>
    {dpcItems.map(({ icon, copy, gridArea }) => (
      <GridItem styles={{ "--grid-area": gridArea }} icon={icon}>
        {copy.map(c => (
          <p>{c}</p>
        ))}
      </GridItem>
    ))}
  </GridWrapper>
)

const GridWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "clock phone house"
    "doctor timer board"
    "veggies hoop syringe";
`

const dpcItems = [
  {
    icon: ClockIcon,
    copy: ["30min visits with the doctor"],
    gridArea: "clock",
  },
  {
    icon: PhoneIcon,
    copy: ["text, phone, telemedicine"],
    gridArea: "phone",
  },
  {
    icon: HouseIcon,
    copy: ["at home visits for newborns"],
    gridArea: "house",
  },
  {
    icon: DoctorIcon,
    copy: ["in-office visits"],
    gridArea: "doctor",
  },
  {
    icon: TimerIcon,
    copy: ["minimal or no wait times in waiting room"],
    gridArea: "timer",
  },
  {
    icon: BoardIcon,
    copy: [
      "teaching how to cook healthy meals",
      "(with budget and timing in mind)",
    ],
    gridArea: "board",
  },
  {
    icon: VeggiesIcon,
    copy: ["Lifestyle Medicine teachings"],
    gridArea: "veggies",
  },
  {
    icon: HoopIcon,
    copy: ["sport physicals"],
    gridArea: "hoop",
  },
]

export default Grid
