import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const GridItem = ({ icon, children, ...props }) => {
  const IconTag = icon

  return (
    <StyledGridItem {...props}>
      <IconWrapper>
        <IconTag />
      </IconWrapper>
      <CopyWrapper>{children}</CopyWrapper>
    </StyledGridItem>
  )
}

const StyledGridItem = styled.article`
  grid-area: var(--grid-area);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.div``

const CopyWrapper = styled.div`
  flex: 1;
  margin-top: 6.4px;
  font-size: ${12 / 16}rem;
  text-align: center;
  line-height: 1.2;
  /* ----------
    BORDER BETWEEN ICON & CAPTION
  ---------- */
  &:before {
    position: relative;
    top: -6.4px;
    display: block;
    content: "";
    width: 70px;
    height: 1px;
    margin: 0 auto;
    background-color: white;
  }

  & p {
    margin-bottom: 8px;
  }
`

GridItem.propTypes = {
  icon: PropTypes.node.isRequired,
}

export default GridItem
