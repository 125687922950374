import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BoardIcon = () => (
  <StaticImage
    src="../../images/dpcBoard.png"
    placeholder="blurred"
    loading="lazy"
    layout="fixed"
    height={80}
    alt="minimalist outline of a cutting board with a chef's knife carrots, tofu, tomatoes"
  />
)

export default BoardIcon
