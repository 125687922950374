import React from "react"
import styled from "styled-components"

import CalendlyButton from "./calendlyButton"

const Calendly = () => (
  <RootWrapper>
    <Line>
      <Header>READY TO BECOME A MEMBER?</Header>
    </Line>
    <SubHeader>
      Click below to schedule a virtual meet &amp; greet with the Doctor Beet!
    </SubHeader>
    <CalendlyButton
      url="https://calendly.com/thetinybeet/meet-greet"
      pageSettings={{
        hideLandingPageDetails: true,
        hideEventTypeDetails: true,
      }}
    />
  </RootWrapper>
)

const RootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
  color: white;
`

const Line = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;

  &:before {
    content: "";
    position: absolute;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const Header = styled.h2`
  font-size: 1.25rem;
  margin: 0;
  padding: 0.5em;
  text-align: center;
`

const SubHeader = styled.h3`
  font-size: 1.8rem;
  font-weight: normal;
  text-align: center;
`

export default Calendly
