import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import useRefDimensions from "../hooks/useRefDimensions"

const Testimonial = ({ name, testimonial }) => {
  const testimonialEl = useRef()

  const [gridRowEnd, setGridRowEnd] = useState(`span 10`)

  const { height } = useRefDimensions(testimonialEl)

  useEffect(() => {
    const rowEnd = Math.floor(height / 20 + 3)
    setGridRowEnd(`span ${rowEnd}`)
  }, [height])

  // generate random color for name
  const color = `var(--${
    ["beet", "blueberry", "carrot"][Math.floor(Math.random() * Math.floor(3))]
  })`

  return (
    <RootWrapper style={{ "--grid-row-end": gridRowEnd }}>
      <blockquote>
        <Name style={{ "--color": color }}>{name}</Name>
        <p ref={testimonialEl}>{testimonial}</p>
      </blockquote>
    </RootWrapper>
  )
}

const RootWrapper = styled.li`
  width: 100%;
  max-width: 50ch;
  height: 100%;
  grid-row-end: var(--grid-row-end);
`

const Name = styled.cite`
  margin-bottom: 8px;
  font-size: ${20 / 16}rem;
  font-weight: 700;
  color: var(--color);
  font-style: normal;
`

export default Testimonial
