import React from "react"
import styled from "styled-components"

import Heading from "./typography/heading"
import Body from "./typography/bodyCopy"
import Dual from "./dual"
import MotherDaughter from "./images/motherDaughter"
import Section from "./section"
import Button from "./button"

const Lifestyle = () => (
  <Section color="pri-med" background="circles">
    <Dual>
      <CopyWrapper>
        <Heading tag="h2">A Lifestyle Medicine Approach</Heading>
        <Body>
          The Lifestyle Medicine approach is comprehensive, evidence-based, and
          focuses on the root cause of the patient's health. It involves
          counseling patients on their lifestyle choices like healthy eating,
          getting a good night’s sleep, the importance of physical activity and
          maintaining healthy relationships. All with the goal of preventing
          chronic diseases in your kids’ future...and having a bit of fun along
          the way.
        </Body>
        <Button
          to="/learn-more#lifestyle"
          title="Learn more about Lifestyle Medicine"
        >
          Learn more about Lifestyle Medicine
        </Button>
      </CopyWrapper>
      <ImageWrapper>
        <MotherDaughter />
      </ImageWrapper>
    </Dual>
  </Section>
)

const CopyWrapper = styled.div`
  max-width: 515px;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    order: 1;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 50%;
  max-width: 515px;
  overflow: hidden;
`

export default Lifestyle
