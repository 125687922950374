import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PhoneIcon = props => (
  <StaticImage
    src="../../images/dpcPhone.png"
    placeholder="blurred"
    loading="lazy"
    layout="fixed"
    height={80}
    alt="minimalist outline of a phone with a masked doctor on the screen"
  />
)

export default PhoneIcon
